import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import Hero from "../components/Hero"
import OutboundLink from "../components/OutboundLink"
import PageCards from "../components/PageCards"
import MissionImage from "../images/theme/img-about-mission.jpg"
// import EmailLink from "../components/EmailLink"
// import EmailCTAImage from "../images/theme/img-educators-af-infographic-TEMP.png"
import SubFooterImage from "../images/theme/img-subfooter-about.jpg"
import CVSHealthLogo from "../images/theme/logo-cvs-health.svg"
import DiscoveryEducationLogo from "../images/global/img-de-logo.png"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Hero
        title="About"
        infographic="img-hero-infographic-about.png"
        body="CVS Health and Discovery Education have partnered to expand the Pharmacists Teach program into the classroom with a no-cost prevention program, Dose of Knowledge."
        image="img-hero-about.jpg"
      />
    </Section>

    {/***************  MISSION CALLOUT ***************/}
    <Section className="mission midgray-background">
      <Container>
        <Row>
          <Column span={5} className="white-background">
            <h2>Mission</h2>
            <p>
              <strong>Dose of Knowledge’s</strong> mission is to empower
              educators and pharmacists to address substance misuse and educate
              students to make good decisions for the health and well-being of
              themselves and their community.
            </p>
          </Column>
          <Column span={7}>
            <img src={MissionImage} className="mission-image" alt="Mission" />
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  THREE COLUMN CALLOUT ***************/}
    <Section className="about__colcallout three__column-callouts midgray-background">
      <Container>
        <PageCards
            cards={[
              {
                to: "/educators/",
                label: "Educators",
                image: "img-hero-infographic-educators.png",
                description:
                  "Find standards-aligned resources to start the conversation around substance misuse in the classroom.",
              },
              {
                to: "/pharmacists/",
                label: "Pharmacists",
                image: "img-hero-infographic-pharmacists.png",
                description:
                  "Bring your expertise into the classroom with easy-to-implement lesson plans designed to help students make healthy decisions around prescription and illicit drugs.",
              },
              {
                to: "/students/",
                label: "Students",
                image: "img-hero-infographic-students.png",
                description:
                  "Find interactive resources designed to empower students to make smart, healthy choices in the face of peer pressure.",
              },
              {
                to: "/caregivers/",
                label: "Caregivers",
                image: "img-hero-infographic-caregivers.png",
                description:
                  "Support students at home with evidence-based resources that can be used to guide discussions about the dangers of substance misuse.",
              },
            ]}
          />
      </Container>
    </Section>

    {/***************  DISCOVERY EDUCATION EXPERIENCE CALLOUT ***************/}
    {/* <Section className="dex">
      <Container>
        <Row>
          <Column span={6}>
            <h3>Discovery Education Experience</h3>
            <p>
              CVS Health has partnered with Discovery Education to deliver
              Discovery Education Experience to five states across the U.S. at
              no-cost to school districts. CVS Health hopes to fuel student
              learning and ensure that education is accessible and equitable.
              The Discovery Education Experience is the evolution of Discovery
              Education's most popular digital service—Discovery Education
              Streaming—already used by one-third of US classrooms. Discovery
              Education Experience features new personalization of educator
              needs, curated "Best of" content, upgraded creation and
              collaboration tools, and embedded professional learning.
            </p>
            <p>
              <a
                href="https://www.discoveryeducation.com/solutions/streaming-plus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join the conversation
              </a>
            </p>
          </Column>
        </Row>
      </Container>
    </Section> */}

    {/***************  ABOUT THE PARTNERSHIP CALLOUT ***************/}
    <Section className="about-partnership midgray-background">
      <Container>
        <Row>
          <Column span={9}>
            <h3>About the Partnership</h3>
            <p>
              CVS Health and Discovery Education are invested in the long-term
              health and well-being of students nationwide. To reach this goal,
              CVS Health and Discovery Education have partnered to create Dose
              of Knowledge, a Pharmacists Teach program designed to provide
              educators and students nationwide with resources to address
              substance misuse. Through this partnership, both organizations
              hope to change the educational landscape and lead the conversation
              toward long-term solutions. Join our mission today and help
              provide your students with the tools for a successful and healthy
              future.
            </p>
          </Column>
        </Row>
        <Row>
          <Column span={3}>
            <img src={CVSHealthLogo} className="cvs__logo" alt="CVS Health" />
          </Column>
          <Column span={8}>
            <h4>CVS Health</h4>
            <p>
              At CVS Health, we share a clear purpose: helping people on their
              path to better health. Through our health services, plans and
              community pharmacists, we’re pioneering a bold new approach to
              total health. Making quality care more affordable, accessible,
              simple and seamless, to not only help people get well, but help
              them stay well in body, mind and spirit.
            </p>
          </Column>
        </Row>
        <Row>
          <Column span={3}>
            <img
              src={DiscoveryEducationLogo}
              className="discovery__logo"
              alt="Discovery Education"
            />
          </Column>
          <Column span={8}>
            <h4>Discovery Education</h4>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in nearly 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at{" "}
              <a
                href="https://www.discoveryeducation.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.discoveryeducation.com.
              </a>
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className="subfooter">
      <Container>
        <Row>
          <Column span={4}>
            <img
              src={SubFooterImage}
              className="subfooter-image"
              alt="Title: Facts, Info etc."
            />
          </Column>
          <Column span={8}>
            <h3>About Pharmacists Teach</h3>
            <p>
              CVS Health created <strong>Pharmacists Teach</strong> in 2015 to
              bring pharmacists into the classroom to help educate the public
              about the challenges surrounding substance misuse. The{" "}
              <strong>Pharmacists Teach</strong> program has now been expanded
              to include <strong>Dose of Knowledge</strong>, a program created
              in partnership with Discovery Education to provide
              standards-aligned resources to educators and pharmacists across
              the U.S. The <strong>Pharmacists Teach</strong> program strives to
              reach nearly 2M students by 2023 and to empower students across
              the country to make healthy choices to prevent the misuse of
              prescription and illicit drugs.
            </p>
            <OutboundLink to="https://cvshealth.com/social-responsibility/corporate-social-responsibility/pharmacists-teach">
              <button className="global__button black">Learn More</button>
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
)
export default AboutPage
